<template>
    <b-container class="m-0 p-0" v-if="currentEntry.activityType_needInputs">
      <!-- GrowthStage -->
      <div>
        <h4 class="subtitle-form overload-subtitle mb-2 mt-3">Stade de croissance</h4>
          <div class="text-infos bg-white" v-if="currentEntry.growthStage_designation" >
            <span> {{ currentEntry.growthStage_designation }} </span>
          </div>
          <p class="text-activity-notes empty-attr text-left"v-else-if="currentEntry.growthStage === null">
            <span> Aucun stade de croissance renseigné </span>
          </p>
          <p class="text-activity-notes text-left" v-else-if="currentEntry.growthStage === undefined">
            <span> {{ $t("activities.multiple.details.growthstage") }} </span>
          </p>
      </div>
    </b-container>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

export default {
  mixins: [DetailUpdateMixin],
  name: "detail-growth-stage",
};
</script>

<style lang="scss" scoped>
/**
 * Overload subtitle-
 */
.overload-subtitle {
  margin: 0px 0px 0px 0px;
}
</style>
